<template>
  <b-card>
    <b-form-group
        label="ไลน์ (LINE)"
        label-for="lineId"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="lineId"
          rules=""
      >
        <b-input-group prepend="ID">
          <b-input
              id="lineId"
              v-model="form.lineId"
              :state="errors[0] ? false : null"
              placeholder="@example"
          >
          </b-input>
        </b-input-group>
      </ValidationProvider>
    </b-form-group>

    <b-form-group
        label="รูปไลน์ QR Code"
        label-for="qr_code"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="line_qr_code"
          rules=""
      >
        <b-form-file
            id="line_qr_code"
            v-model="form.lineQrCode"
            accept="image/*"
            readonly
            :state="errors[0] ? false : null"
        >
        </b-form-file>
      </ValidationProvider>
      <b-form-text>
        <a
            v-if="form.lineQrCode"
            target="_blank"
            :href="form.lineQrCode"
        >
          {{ form.lineQrCode || '' }}
        </a>
        <b-link
            v-if="
                  form.lineQrCode && typeof form.lineQrCode !== 'string'
                "
            class="text-danger"
            @click="form.lineQrCode = null"
        >
          {{ $t('buttons.remove') }}
        </b-link>
      </b-form-text>
    </b-form-group>
    <b-form-group
        :label="`${$t('contact.message')}`"
        label-for="contact"
    >
      <ValidationProvider
          v-slot="{ errors }"
          name="contact"
      >
        <b-form-textarea
            id="contact"
            v-model="form.contact"
            :state="errors[0] ? false : null"
        ></b-form-textarea>
      </ValidationProvider>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | การติดต่อ',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        lineId: '',
        lineQrCode: '',
        contact: '',
      }),
    },
  },
  data() {
    return {
      form: {},
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      lineId: this.agentData.lineId,
      lineQrCode: this.agentData.lineQrCode,
      contact: this.agentData.contact
    }
  },
}
</script>

<style></style>
