var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form-group',{attrs:{"label":"ไลน์ (LINE)","label-for":"lineId"}},[_c('ValidationProvider',{attrs:{"name":"lineId","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"ID"}},[_c('b-input',{attrs:{"id":"lineId","state":errors[0] ? false : null,"placeholder":"@example"},model:{value:(_vm.form.lineId),callback:function ($$v) {_vm.$set(_vm.form, "lineId", $$v)},expression:"form.lineId"}})],1)]}}])})],1),_c('b-form-group',{attrs:{"label":"รูปไลน์ QR Code","label-for":"qr_code"}},[_c('ValidationProvider',{attrs:{"name":"line_qr_code","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"line_qr_code","accept":"image/*","readonly":"","state":errors[0] ? false : null},model:{value:(_vm.form.lineQrCode),callback:function ($$v) {_vm.$set(_vm.form, "lineQrCode", $$v)},expression:"form.lineQrCode"}})]}}])}),_c('b-form-text',[(_vm.form.lineQrCode)?_c('a',{attrs:{"target":"_blank","href":_vm.form.lineQrCode}},[_vm._v(" "+_vm._s(_vm.form.lineQrCode || '')+" ")]):_vm._e(),(
                _vm.form.lineQrCode && typeof _vm.form.lineQrCode !== 'string'
              )?_c('b-link',{staticClass:"text-danger",on:{"click":function($event){_vm.form.lineQrCode = null}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")]):_vm._e()],1)],1),_c('b-form-group',{attrs:{"label":("" + (_vm.$t('contact.message'))),"label-for":"contact"}},[_c('ValidationProvider',{attrs:{"name":"contact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contact","state":errors[0] ? false : null},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }